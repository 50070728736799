import TEditor from './TEditor.vue';
import axios from 'axios';
import { javascript } from '@codemirror/lang-javascript';
import CodeMirror from 'vue-codemirror6';
import { oneDark } from '@codemirror/theme-one-dark';
export default {
  components: {
    TEditor,
    CodeMirror
  },
  setup() {
    const extensions1 = [javascript(), oneDark];
    const extensions2 = [javascript()];
    return {
      extensions1,
      extensions2
    };
  },
  data() {
    return {
      mainHeight: '',
      course_content: '',
      currentRow: {},
      currentColumns: [{
        name: 'body',
        title: '',
        type: 'TEditor'
      }],
      source_current_id: '',
      source_current_type: '',
      source_current_url: ''
    };
  },
  created() {
    this.getInit();
  },
  methods: {
    getInit() {
      this.mainHeight = window.innerHeight - 140;
      this.source_current_id = window.sessionStorage.getItem('source_current_id');
      this.source_current_type = window.sessionStorage.getItem('source_current_type');
      this.source_current_url = window.sessionStorage.getItem('source_current_url');
      let _this = this;
      axios.get(this.source_current_url).then(response => {
        _this.course_content = response.data;
      }).catch(error => {
        console.error(error);
      });
      // this.course_content = this.getRemoteContent(this.source_current_url);
    },

    async getRemoteContent(api) {
      return await this.withLoading(this.getData(api));
    },
    open() {
      window.open(this.source_current_url);
    },
    //保存用户信息
    async submit() {
      let method = '/saveHtmls';
      if (this.source_current_type == 1) {
        method = '/saveStaticHtmls';
      }
      //编辑
      let data = {};
      let params = {};
      params.ephemeralParam = {
        id: this.source_current_id,
        course_content: this.course_content
      };
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, method));
      if (res.code >= 0) {
        this.successMsgBox('保存成功！');
      } else {
        this.errorMsgBox(res.message);
      }
    },
    getContent(content) {
      console.log(content);
      this.currentRow.course_content = content;
    }
  }
};