export default {
  created() {
    this.getInit();
  },
  data() {
    return {
      size: "meduim",
      radio1: "小程序登录",
      tableMaxHeight: "",
      dataList: [],
      page: {
        pageSize: 20,
        startPage: 1,
        totalResult: 0
      }
    };
  },
  methods: {
    getInit() {
      this.tableMaxHeight = window.innerHeight - 210;
      this.getMyAccount();
    },
    sizeChange(val) {
      this.page.startPage = 1;
      this.page.pageSize = val;
      this.getMyAccount();
    },
    currentChange(val) {
      this.page.startPage = val;
      this.getMyAccount();
    },
    async getMyAccount() {
      let method = "/getWxLogin";
      if (this.radio1 == "反馈管理") {
        method = "/getFeedback";
      }
      let data = {};
      let params = {};
      params.page = this.page;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, method));
      if (res.code >= 0) {
        this.dataList = res.extra;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    }
  }
};