export default {
  created() {
    this.getInit();
  },
  data() {
    return {
      size: 'meduim',
      model_simple_cloud_admin_account: {},
      dataList: [],
      page: {
        pageSize: 20,
        startPage: 1,
        totalResult: 0
      },
      accountDialogShow: false,
      currentRow: {},
      editType: '',
      queryModel: {},
      currentColumns: [{
        name: 'note_name',
        title: '名称',
        isNull: 1
      }, {
        name: 'type',
        title: '类别'
      }, {
        name: 'note_desc',
        title: '描述',
        type: 'textarea'
      }]
    };
  },
  methods: {
    getInit() {
      //初始化
      this.queryModel = {};
      this.getMyAccount();
    },
    sizeChange(val) {
      this.page.startPage = 1;
      this.page.pageSize = val;
      this.getMyAccount();
    },
    currentChange(val) {
      this.page.startPage = val;
      this.getMyAccount();
    },
    edit(row) {
      this.currentRow = row;
      this.accountDialogShow = true;
    },
    async deleteWeb(id) {
      let data = {};
      let params = {};
      params.ephemeralParam = id;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, '/deleteNote'));
      if (res.code >= 0) {
        this.getMyAccount();
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async getMyAccount() {
      let data = {};
      let params = {};
      params.ephemeralParam = this.queryModel;
      params.page = this.page;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, '/getNote'));
      if (res.code >= 0) {
        this.dataList = res.extra;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    //保存用户信息
    async submit() {
      //编辑
      let data = {};
      let params = {};
      params.ephemeralParam = this.currentRow;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, '/saveNote'));
      if (res.code >= 0) {
        this.getMyAccount();
        this.accountDialogShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    openEdit(row, editType) {
      this.accountDialogShow = true;
    },
    searchData() {
      alert('这里是搜索！');
    },
    clearSearch() {
      alert('清除所有搜索条件！');
    },
    handleSelectionChange(val) {
      alert('选中数据！');
    }
  }
};