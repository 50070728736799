import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
const _withScopeId = n => (_pushScopeId("data-v-4f344c60"), n = n(), _popScopeId(), n);
const _hoisted_1 = {
  style: {
    "padding": "10px",
    "width": "100%"
  }
};
const _hoisted_2 = ["href"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_form_item = _resolveComponent("el-form-item");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_input = _resolveComponent("el-input");
  const _component_el_row = _resolveComponent("el-row");
  const _component_el_form = _resolveComponent("el-form");
  const _component_a_table = _resolveComponent("a-table");
  const _component_el_dialog = _resolveComponent("el-dialog");
  const _component_el_radio = _resolveComponent("el-radio");
  const _component_el_radio_group = _resolveComponent("el-radio-group");
  const _component_TEditor = _resolveComponent("TEditor");
  const _directive_loading = _resolveDirective("loading");
  return _openBlock(), _createElementBlock("div", {
    id: "html-manager",
    style: _normalizeStyle({
      backgroundColor: '#f4f4f4',
      minHeight: _ctx.mainHeight,
      overflow: 'hidden',
      width: '100%'
    })
  }, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_el_row, null, {
    default: _withCtx(() => [_createVNode(_component_el_form, {
      style: {
        "width": "100%"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_row, {
        gutter: 10
      }, {
        default: _withCtx(() => [_createVNode(_component_el_col, {
          span: 3
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: "primary",
              size: $data.size,
              icon: "plus",
              onClick: $options.gotoHtmlWrite
            }, {
              default: _withCtx(() => [_createTextVNode("新增网页")]),
              _: 1
            }, 8, ["size", "onClick"])]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 4
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: $data.source_current_type == 1 ? 'primary' : 'info',
              size: $data.size,
              onClick: $options.getStatic
            }, {
              default: _withCtx(() => [_createTextVNode("静态内容")]),
              _: 1
            }, 8, ["type", "size", "onClick"]), _createVNode(_component_el_button, {
              type: $data.source_current_type == 0 ? 'primary' : 'info',
              size: $data.size,
              onClick: $options.getMyAccount
            }, {
              default: _withCtx(() => [_createTextVNode("网页内容")]),
              _: 1
            }, 8, ["type", "size", "onClick"])]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 4
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_select, {
              size: $data.size,
              placeholder: "选择路径",
              modelValue: $data.currentType,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.currentType = $event),
              onChange: $options.getMyAccount
            }, {
              default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.radiOptions, (i, index) => {
                return _openBlock(), _createBlock(_component_el_option, {
                  label: i,
                  value: i,
                  key: index
                }, null, 8, ["label", "value"]);
              }), 128))]),
              _: 1
            }, 8, ["size", "modelValue", "onChange"])]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 4,
          style: {
            "text-align": "left"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_input, {
              size: $data.size,
              placeholder: "请输入网页名称",
              modelValue: $data.htmlTitle,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.htmlTitle = $event)
            }, null, 8, ["size", "modelValue"])]),
            _: 1
          })]),
          _: 1
        }), _createVNode(_component_el_col, {
          span: 4,
          style: {
            "text-align": "left"
          }
        }, {
          default: _withCtx(() => [_createVNode(_component_el_form_item, {
            label: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              type: "primary",
              size: $data.size,
              icon: "search",
              onClick: $options.getMyAccount
            }, {
              default: _withCtx(() => [_createTextVNode("搜索")]),
              _: 1
            }, 8, ["size", "onClick"]), _createVNode(_component_el_button, {
              type: "danger",
              size: $data.size,
              icon: "delete",
              onClick: _cache[2] || (_cache[2] = $event => $options.clearSearch())
            }, {
              default: _withCtx(() => [_createTextVNode("清除")]),
              _: 1
            }, 8, ["size"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }), _withDirectives((_openBlock(), _createBlock(_component_a_table, {
    scroll: {
      y: $data.tableMaxHeight
    },
    onChange: $options.handleTableChange,
    dataSource: $data.dataList,
    columns: $data.source_current_type == 0 ? $data.columns : $data.columns1,
    pagination: $data.pagination
  }, {
    bodyCell: _withCtx(({
      column,
      record
    }) => [column.key === 'action' ? (_openBlock(), _createElementBlock(_Fragment, {
      key: 0
    }, [_createVNode(_component_el_button, {
      size: "small",
      type: "success",
      text: "",
      link: "",
      icon: "view"
    }, {
      default: _withCtx(() => [_createElementVNode("a", {
        href: record.access_path,
        target: "_blank"
      }, "查看", 8, _hoisted_2)]),
      _: 2
    }, 1024), $data.source_current_type == 0 && !record.publish_status ? (_openBlock(), _createBlock(_component_el_button, {
      key: 0,
      size: "small",
      type: "primary",
      text: "",
      link: "",
      icon: "edit",
      onClick: $event => $options.confirmPublish(record._id)
    }, {
      default: _withCtx(() => [_createTextVNode(" 审核 ")]),
      _: 2
    }, 1032, ["onClick"])) : $data.source_current_type == 0 ? (_openBlock(), _createBlock(_component_el_button, {
      key: 1,
      size: "small",
      type: "primary",
      text: "",
      link: "",
      icon: "edit",
      onClick: $event => $options.cancelPublish(record._id)
    }, {
      default: _withCtx(() => [_createTextVNode("取消审核")]),
      _: 2
    }, 1032, ["onClick"])) : _createCommentVNode("", true), _createVNode(_component_el_button, {
      size: "small",
      type: "primary",
      text: "",
      link: "",
      icon: "edit",
      onClick: $event => $options.editPage(record)
    }, {
      default: _withCtx(() => [_createTextVNode("编辑")]),
      _: 2
    }, 1032, ["onClick"]), _createVNode(_component_el_button, {
      size: "small",
      type: "primary",
      text: "",
      link: "",
      icon: "edit",
      onClick: $event => $options.editInterview(record)
    }, {
      default: _withCtx(() => [_createTextVNode("网页编辑")]),
      _: 2
    }, 1032, ["onClick"])], 64)) : _createCommentVNode("", true)]),
    _: 1
  }, 8, ["scroll", "onChange", "dataSource", "columns", "pagination"])), [[_directive_loading, $data.loadingData]])]), _createElementVNode("div", null, [_createVNode(_component_el_dialog, {
    title: "内容",
    left: "",
    modelValue: $data.pageShow,
    "onUpdate:modelValue": _cache[4] || (_cache[4] = $event => $data.pageShow = $event),
    width: "35%"
  }, {
    default: _withCtx(() => [_createVNode(_component_el_form, null, {
      default: _withCtx(() => [_createVNode(_component_el_form_item, {
        label: "网页名称"
      }, {
        default: _withCtx(() => [_createVNode(_component_el_input, {
          modelValue: $data.currentRow.html_title,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.currentRow.html_title = $event)
        }, null, 8, ["modelValue"])]),
        _: 1
      }), _createVNode(_component_el_form_item, {
        label: ""
      }, {
        default: _withCtx(() => [_createVNode(_component_el_button, {
          type: "primary",
          onClick: $options.saveHtml
        }, {
          default: _withCtx(() => [_createTextVNode("提交")]),
          _: 1
        }, 8, ["onClick"])]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue"]), _createVNode(_component_el_dialog, {
    title: "内容",
    left: "",
    modelValue: $data.accountDialogShow,
    "onUpdate:modelValue": _cache[5] || (_cache[5] = $event => $data.accountDialogShow = $event),
    width: "55%",
    top: "5vh",
    "before-close": _ctx.handleClose
  }, {
    default: _withCtx(() => [_createVNode(_component_el_row, {
      style: {
        "text-align": "center"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_col, {
        span: 24
      }, {
        default: _withCtx(() => [_createVNode(_component_el_form, {
          "label-width": "100px"
        }, {
          default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.currentColumns, i => {
            return _openBlock(), _createBlock(_component_el_form_item, {
              prop: i.name,
              label: i.title,
              required: i.isNull,
              key: i.name,
              error: "必填",
              "show-message": false
            }, {
              default: _withCtx(() => [i.type == 'radio' ? (_openBlock(), _createBlock(_component_el_radio_group, {
                key: 0,
                modelValue: $data.currentRow[i.name],
                "onUpdate:modelValue": $event => $data.currentRow[i.name] = $event
              }, {
                default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(i.radiOptions, (i, index) => {
                  return _openBlock(), _createBlock(_component_el_radio, {
                    label: i,
                    key: index,
                    value: i
                  }, null, 8, ["label", "value"]);
                }), 128))]),
                _: 2
              }, 1032, ["modelValue", "onUpdate:modelValue"])) : i.type == 'TEditor' ? (_openBlock(), _createBlock(_component_TEditor, {
                key: 1,
                style: {
                  "width": "100%"
                },
                modelValue: $data.currentRow[i.name],
                "onUpdate:modelValue": $event => $data.currentRow[i.name] = $event,
                onGetContent: $options.getContent
              }, null, 8, ["modelValue", "onUpdate:modelValue", "onGetContent"])) : (_openBlock(), _createBlock(_component_el_input, {
                key: 2,
                size: $data.size,
                placeholder: i.desc,
                modelValue: $data.currentRow[i.name],
                "onUpdate:modelValue": $event => $data.currentRow[i.name] = $event
              }, null, 8, ["size", "placeholder", "modelValue", "onUpdate:modelValue"]))]),
              _: 2
            }, 1032, ["prop", "label", "required"]);
          }), 128)), _createVNode(_component_el_form_item, {
            label: ""
          }, {
            default: _withCtx(() => [_createVNode(_component_el_button, {
              icon: "CircleCheck",
              size: $data.size,
              type: "primary",
              onClick: $options.submit
            }, {
              default: _withCtx(() => [_createTextVNode("提交")]),
              _: 1
            }, 8, ["size", "onClick"])]),
            _: 1
          })]),
          _: 1
        })]),
        _: 1
      })]),
      _: 1
    })]),
    _: 1
  }, 8, ["modelValue", "before-close"])])], 4);
}