import "core-js/modules/es.array.push.js";
import TEditor from './TEditor.vue';
export default {
  components: {
    TEditor
  },
  created() {
    this.getInit();
  },
  data() {
    return {
      content: '',
      size: 'meduim',
      pagination: {
        showQuickJumper: true,
        pageSizeOptions: [3, 6, 9, 12, 15, 20, 30]
      },
      columns1: [{
        title: '地址',
        dataIndex: 'access_path',
        key: 'access_path'
      }, {
        title: '操作',
        key: 'action'
      }],
      columns: [{
        title: '网页名称',
        dataIndex: 'html_title',
        key: 'html_title',
        width: 600
        //align: 'center'
      }, {
        title: '文章类型',
        dataIndex: 'article_type',
        key: 'article_type',
        width: 100
      }, {
        title: '作者',
        dataIndex: 'user_name',
        key: 'user_name',
        width: 200
      }, {
        title: '访问次数',
        dataIndex: 'access_times',
        key: 'access_times',
        width: 100
      }, {
        title: '创建时间',
        dataIndex: 'create_dtme',
        key: 'create_dtme',
        width: 300
      }, {
        title: '操作',
        key: 'action'
      }],
      model_simple_cloud_admin_account: {},
      dataList: [],
      page: {
        pageSize: 20,
        startPage: 1,
        totalResult: 0
      },
      accountDialogShow: false,
      currentRow: {},
      pageShow: false,
      queryModel: {},
      currentType: '',
      htmlTitle: '',
      tableMaxHeight: '',
      radiOptions: ['/code/java/', '/code/python/', '/code/project/', '/code/mysql/', '/code/redis/', '/code/electron/', '/code/node/', '/code/MongDB/', '/code/vue/', '/code/react/', '/code/html/', '/code/js/', '/code/css3/', '/code/wx/', '/code/uniapp/', '/code/PHP/', '/code/linux/', '/code/nginx/', '/code/go/', '/code/flutter/', '/course/java/', '/course/vue/', '/course/css3/', '/course/react/', '/course/python/', '/course/redis/', '/course/node/', '/course/electron/', '/course/mysql/', '/course/springcloud/', '/course/kafka/', '/course/go/', '/course/netty/', '/course/elestricsearch/', '/course/PHP/', '/course/go/', '/course/flutter/', '/download/', '/mianshiti/javabase/', '/mianshiti/javaio/', '/mianshiti/javaset/', '/mianshiti/javathread/', '/mianshiti/queue/', '/mianshiti/datastructure/', '/mianshiti/jvm/', '/mianshiti/mysql/', '/mianshiti/redis/', '/mianshiti/kafka/', '/mianshiti/rocketMQ/', '/mianshiti/spring/', '/mianshiti/springboot/', '/mianshiti/springcloud/', '/mianshiti/html5/', '/mianshiti/vue/', '/mianshiti/es/'],
      currentColumns: [{
        name: 'course_content',
        type: 'TEditor',
        title: '内容'
      }],
      source_current_type: '',
      loadingData: false
    };
  },
  methods: {
    getInit() {
      this.tableMaxHeight = window.innerHeight - 260;
      //初始化
      this.queryModel = {};
      if (window.sessionStorage.getItem('currentType')) {
        this.currentType = window.sessionStorage.getItem('currentType');
      }
      this.getMyAccount();
    },
    editInterview(row) {
      // let access_path = row.access_path.replace(
      //   /daimane.com/g,
      //   "itlifetime.com/temp"
      // );

      window.sessionStorage.setItem('source_current_url', row.access_path);
      window.sessionStorage.setItem('source_current_id', row.id);
      window.sessionStorage.setItem('source_current_type', this.source_current_type);
      this.$router.push('htmlEditor');
    },
    editPage(row) {
      this.currentRow = row;
      this.pageShow = true;
    },
    openHtml(row) {
      window.open(row.access_path);
    },
    getContent(content) {
      this.currentRow.course_content = content;
    },
    sizeChange(val) {
      this.page.startPage = 1;
      this.page.pageSize = val;
      this.getMyAccount();
    },
    currentChange(val) {
      this.page.startPage = val;
      this.getMyAccount();
    },
    deleteWeb(id) {
      this.$confirm('确认删除吗，会删除所有相关数据？').then(() => {
        this.doDeleteWeb(id);
      }).catch(() => {});
    },
    //修改网页名称html_title
    async saveHtml() {
      let data = {};
      let params = {};
      params.ephemeralParam = this.currentRow.id;
      params.ephemeralParam1 = this.currentRow.html_title;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, '/saveSourceHtml'));
      if (res.code >= 0) {
        this.getMyAccount();
        this.pageShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async doDeleteWeb(id) {
      let data = {};
      let params = {};
      params.ephemeralParam = id;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, '/deleteHtmls'));
      if (res.code >= 0) {
        this.getMyAccount();
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async getStatic() {
      this.source_current_type = 1;
      let data = {};
      let params = {};
      params.ephemeralParam = this.currentType;
      data.params = JSON.stringify(params);
      this.loadingData = true;
      const res = await this.withLoading(this.apiAll(data, '/getStatic'));
      this.loadingData = false;
      if (res.code >= 0) {
        this.dataList = res.extra;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async cancelPublish(_id) {
      const res = await this.withLoading(this.apiAll2({
        _id: _id
      }, 'cancelPublish'));
      if (res) {
        this.successMsgBox('取消审核成功');
        this.getMyAccount();
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async confirmPublish(_id) {
      const res = await this.withLoading(this.apiAll2({
        _id: _id
      }, 'confirmPublish'));
      if (res) {
        this.successMsgBox('审核成功');
        this.getMyAccount();
      } else {
        this.errorMsgBox(res.message);
      }
    },
    /**
    change: (page, pageSize) => {
    console.log(page);
    },
           
    showSizeChange: (current, size) => {
    alert(current);
    }
    */
    // 表格分页改变时的事件处理函数
    handleTableChange(pagination, filters, sorter) {
      // 用户点击分页的页码或者触发了分页（比如跳转到某一页）时，这里的代码会被调用
      // 你可以在这里请求新的数据，并更新分页配置
      this.pagination = pagination;
      this.page.startPage = pagination.current;
      this.page.pageSize = pagination.pageSize;

      // 假设fetchData是你用来获取表格数据的方法
      this.getMyAccount();
    },
    async getMyAccount() {
      this.source_current_type = 0;
      let data = {};
      let params = {};
      params.page = this.page;
      params.ephemeralParam = this.currentType;
      if (this.currentType) {
        window.sessionStorage.setItem('currentType', this.currentType);
      }
      params.ephemeralParam1 = this.htmlTitle;
      data.params = JSON.stringify(params);

      // const res = await this.withLoading(this.apiAll(data, '/getHtmls'));
      //          if (res.code >= 0) {
      //              this.dataList = res.extra;
      //              this.page = res.page;
      //          } else {
      //              this.errorMsgBox(res.message);
      //          }

      this.loadingData = true;
      const res = await this.withLoading(this.apiAll2({
        page: this.page,
        name: this.htmlTitle
      }, 'getHtmls'));
      this.loadingData = false;
      if (res) {
        this.dataList = res.items;
        this.page = res.page;
        this.pagination.current = this.page.startPage;
        this.pagination.pageSize = this.page.pageSize;
        this.pagination.total = this.page.totalResult;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    gotoHtmlWrite() {
      this.$router.push('htmlWriter');
    },
    //保存用户信息
    async submit() {
      //编辑
      let data = {};
      let params = {};
      let list = [];
      params.ephemeralParam = this.currentRow;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, '/saveCourse'));
      if (res.code >= 0) {
        this.getMyAccount();
        this.accountDialogShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async deleteInterview(id) {
      //   let data = {};
      //   let params = {};
      //   params.ephemeralParam = id;
      //   data.params = JSON.stringify(params);
      //   const res = await this.withLoading(this.apiAll(data, "/deleteInterview"));
      //   if (res.code >= 0) {
      //     this.successMsgBox(res.message);
      //     this.getInit();
      //   } else {
      //     this.errorMsgBox(res.message);
      //   }
    },
    handleRemove(f, fileList) {
      fileList.some((element, index) => {
        if (element.name == f.name) {
          fileList.splice(index, 1);
        }
      });
    },
    searchData() {
      alert('这里是搜索！');
    },
    clearSearch() {
      this.htmlTitle = '';
      this.currentType = '';
      window.sessionStorage.setItem('currentType', this.currentType);
    },
    handleSelectionChange(val) {
      alert('选中数据！');
    }
  }
};