import "core-js/modules/es.array.push.js";
export default {
  created() {
    this.getInit();
  },
  data() {
    return {
      editableTabs: [{
        title: "车次",
        name: "train_no"
      }, {
        title: "车站",
        name: "train_station"
      }],
      editableTabsValue: "train_no",
      size: "meduim",
      model_simple_cloud_admin_account: {},
      dataList: [],
      trainStationDataList: [],
      page: {
        pageSize: 20,
        startPage: 1,
        totalResult: 0
      },
      stationPage: {
        pageSize: 20,
        startPage: 1,
        totalResult: 0
      },
      accountDialogShow: false,
      trainStationDialogShow: false,
      currentRow: {
        total_stations: []
      },
      currentStationRow: {},
      queryModel: {},
      currentType: "",
      currentStationColumns: [{
        name: "city_name",
        title: "城市"
      }, {
        name: "train_station",
        title: "站名"
      }],
      currentColumns: [{
        name: "type",
        type: "radio",
        title: "类型",
        radiOptions: ["列车", "公交", "地铁"]
      }, {
        name: "city_name",
        title: "城市"
      }, {
        name: "train_no",
        title: "车次"
      }, {
        name: "start_station",
        title: "起始站"
      }, {
        name: "end_station",
        title: "终点站"
      }, {
        name: "start_time",
        title: "起始时间"
      }, {
        name: "end_time",
        title: "结束时间"
      }, {
        name: "total_time",
        title: "运行时间"
      }, {
        name: "total_stations",
        type: "list",
        title: "全部站点",
        fields: [{
          name: "station_name"
        }, {
          name: "desc"
        }]
      }],
      total_stations: [{
        station_name: "",
        desc: ""
      }],
      tableMaxHeight: "",
      train_no: ""
    };
  },
  methods: {
    getInit() {
      this.tableMaxHeight = window.innerHeight - 210;

      //初始化
      this.queryModel = {};
      this.getMyAccount();
    },
    newStation() {
      let station = {
        station_name: "",
        desc: ""
      };
      this.total_stations.push(station);
    },
    tabClick(tab) {
      this.editableTabsValue = tab.props.name;
      if (this.editableTabsValue == "train_station") {
        this.getTrainStation();
      } else if (this.editableTabsValue == "train_no") {
        this.getMyAccount();
      }
    },
    editTrainStation(row) {
      this.currentStationRow = row;
      this.trainStationDialogShow = true;
    },
    async editInterview(row) {
      this.accountDialogShow = true;
      this.currentRow = row;
      this.total_stations = row.total_stations;
      let data = {};
      let params = {};
      params.ephemeralParam = this.currentRow.train_no;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/getTrainstationsByTrainNo"));
      if (res.code >= 0) {
        this.total_stations = res.extra;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async getTrainStation() {
      let data = {};
      let params = {};
      params.page = this.stationPage;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/getTrainStation"));
      if (res.code >= 0) {
        this.trainStationDataList = res.extra;
        this.stationPage = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    sizeChange(val) {
      this.page.startPage = 1;
      this.page.pageSize = val;
      this.getMyAccount();
    },
    currentChange(val) {
      this.page.startPage = val;
      this.getMyAccount();
    },
    stationPageSizeChange(val) {
      this.stationPage.startPage = 1;
      this.stationPage.pageSize = val;
      this.getTrainStation();
    },
    stationPageCurrentPage(val) {
      this.stationPage.startPage = val;
      this.getTrainStation();
    },
    deleteWeb(id) {
      this.$confirm("确认删除吗，会删除所有相关数据？").then(() => {
        this.doDeleteWeb(id);
      }).catch(() => {});
    },
    async doDeleteWeb(id) {
      let data = {};
      let params = {};
      params.ephemeralParam = id;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/deleteTrain"));
      if (res.code >= 0) {
        this.getMyAccount();
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async getMyAccount(currentType) {
      if (currentType) {
        this.currentType = currentType;
      }
      this.source_current_type = 0;
      let data = {};
      let params = {};
      params.page = this.page;
      params.ephemeralParam = this.currentType;
      params.ephemeralParam1 = this.train_no;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/getTrain"));
      if (res.code >= 0) {
        this.dataList = res.extra;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    //保存用户信息
    async submit() {
      //编辑
      let data = {};
      let params = {};
      this.currentRow.total_stations = this.total_stations;
      params.ephemeralParam = this.currentRow;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/saveTrain"));
      if (res.code >= 0) {
        this.getMyAccount();
        this.accountDialogShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async saveTrainStation() {
      //编辑
      let data = {};
      let params = {};
      params.ephemeralParam = this.currentStationRow;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/saveTrainStation"));
      if (res.code >= 0) {
        this.getTrainStation();
        this.trainStationDialogShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async deleteInterview(id) {
      //   let data = {};
      //   let params = {};
      //   params.ephemeralParam = id;
      //   data.params = JSON.stringify(params);
      //   const res = await this.withLoading(this.apiAll(data, "/deleteInterview"));
      //   if (res.code >= 0) {
      //     this.successMsgBox(res.message);
      //     this.getInit();
      //   } else {
      //     this.errorMsgBox(res.message);
      //   }
    },
    searchData() {
      alert("这里是搜索！");
    },
    clearSearch() {
      alert("清除所有搜索条件！");
    },
    handleSelectionChange(val) {
      alert("选中数据！");
    }
  }
};