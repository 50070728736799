import "core-js/modules/es.array.push.js";
import { nextTick } from 'vue';
export default {
  data() {
    return {
      type: true,
      model: {
        username: '',
        password: ''
      },
      eyeShow: true,
      subText: '登陆',
      pwdType: 'password',
      rules: {
        username: [{
          required: true,
          message: '用户名不能为空'
        }],
        password: [{
          required: true,
          message: '密码不能为空'
        }]
      },
      loginInfor: {
        logoPath: '上海考拉科技信息软件公司 @copyright 2023 ',
        loginBtnPosition: 1,
        backgroundImgPath: require('../assets/bgb.jpg'),
        loginColor: '#409eff',
        color: '#FFF',
        loginName: '欢迎'
      },
      subLoad: false,
      forceLogin: ''
    };
  },
  created() {
    nextTick(() => {
      document.getElementById('userName')?.focus();
    });
  },
  methods: {
    passShow() {
      this.eyeShow = !this.eyeShow;
      this.pwdType = this.pwdType === 'password' ? 'text' : 'password';
    },
    async doLogin() {
      const ar = _.split(window.location.href, '#');
      const result = '';
      let params = {};
      let data = {};
      data.adminName = this.model.userName;
      data.adminPassword = this.model.password;
      this.subLoad = true;
      this.subText = '登陆中';
      const loginMes = await this.withLoading(this.apiAll2(data, '/loginAdmin'));
      this.subLoad = false;
      this.subText = '登陆';
      if (loginMes.code == 0) {
        sessionStorage.setItem('newFreeAdminInfo', JSON.stringify(loginMes.extra.adminInfo));
        sessionStorage.setItem('newFreeAccessToken', loginMes.extra.accessToken);

        //默认跳到账户页面
        let tab = {};
        tab.title = '资源管理';
        tab.name = 'freeManager';
        let editableTabs = [];
        editableTabs.push(tab);
        window.sessionStorage.setItem('freeTabs', JSON.stringify(editableTabs));
        window.sessionStorage.setItem('freeCurrentTab', 'freeManager');
        this.$router.push('freeManager');
      } else {
        this.errorMsgBox(loginMes.message);
      }
    },
    getMarginLeft() {
      if (this.loginInfor.loginBtnPosition == 1) {
        //居中
        return '0';
      } else if (this.loginInfor.loginBtnPosition == 2) {
        return 'auto';
      } else if (this.loginInfor.loginBtnPosition == 3) {
        return '65%';
      }
    },
    onSubmit(formName) {
      if (sessionStorage.ico) {
        this.$store.commit('save_icon', sessionStorage.ico);
      }
      this.$refs[formName].validate(async valid => {
        if (!valid) {
          this.errorMsgBox('用户名或密码不能为空');
          return false;
        } else {
          this.doLogin();
        }
      });
    }
  }
};