export default {
  created() {
    this.getInit();
  },
  data() {
    return {
      size: "meduim",
      tableMaxHeight: "",
      model_simple_cloud_admin_account: {},
      dataList: [],
      page: {
        pageSize: 20,
        startPage: 1,
        totalResult: 0
      },
      accountDialogShow: false,
      currentRow: {
        type1: 0
      },
      editType: "",
      queryModel: {
        type1: "",
        type2: "",
        wordName: ""
      },
      currentColumns: [{
        name: "type1",
        title: "类一",
        type: "radio2",
        radiOptions: [{
          value: 0,
          label: "自考树"
        }, {
          value: 1,
          label: "四级"
        }, {
          value: 2,
          label: "专业术语"
        }, {
          value: 3,
          label: "综合词库"
        }]
      }, {
        name: "type2",
        title: "类型二",
        type: "radio2",
        radiOptions: [{
          value: 0,
          label: "基础"
        }, {
          value: 1,
          label: "核心"
        }, {
          value: 2,
          label: "进阶"
        }]
      }, {
        name: "word_name",
        title: "名称",
        isNull: 1
      }, {
        name: "word_meaning",
        title: "意思"
      }, {
        name: "word_pronunciation",
        title: "发音"
      }]
    };
  },
  methods: {
    getInit() {
      this.tableMaxHeight = window.innerHeight - 210;

      //初始化
      this.queryModel = {};
      this.getMyAccount();
    },
    sizeChange(val) {
      this.page.startPage = 1;
      this.page.pageSize = val;
      this.getMyAccount();
    },
    currentChange(val) {
      this.page.startPage = val;
      this.getMyAccount();
    },
    async deleteWeb(id) {
      let data = {};
      let params = {};
      params.ephemeralParam = id;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/deleteWord"));
      if (res.code >= 0) {
        this.getMyAccount();
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async getMyAccount() {
      let data = {};
      let params = {};
      if (this.queryModel && Object.keys(this.queryModel).length !== 0) {
        params.ephemeralParam = this.queryModel;
      }
      params.page = this.page;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/getWordsForAdmin"));
      if (res.code >= 0) {
        this.dataList = res.extra;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    //保存用户信息
    async submit() {
      //编辑
      let data = {};
      let params = {};
      params.ephemeralParam = this.currentRow;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/saveWord"));
      if (res.code >= 0) {
        this.getMyAccount();
        this.accountDialogShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async deleteInterview(id) {
      //   let data = {};
      //   let params = {};
      //   params.ephemeralParam = id;
      //   data.params = JSON.stringify(params);
      //   const res = await this.withLoading(this.apiAll(data, "/deleteInterview"));
      //   if (res.code >= 0) {
      //     this.successMsgBox(res.message);
      //     this.getInit();
      //   } else {
      //     this.errorMsgBox(res.message);
      //   }
    },
    handleRemove(f, fileList) {
      fileList.some((element, index) => {
        if (element.name == f.name) {
          fileList.splice(index, 1);
        }
      });
    },
    edit(row) {
      this.currentRow = row;
      this.accountDialogShow = true;
    },
    openEdit(row, editType) {
      this.accountDialogShow = true;
    },
    searchData() {
      alert("这里是搜索！");
    },
    clearSearch() {
      alert("清除所有搜索条件！");
    },
    handleSelectionChange(val) {
      alert("选中数据！");
    }
  }
};