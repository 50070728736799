import { unref as _unref, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
import { ref, watch } from 'vue';
// import { errorMsgBox, successMsgBox, confirmBox, apiAll, formDataApi, showSimpleDate } from '~/composables/myFunction.js';

export default {
  __name: 'vueMarkdown',
  props: {
    value: {
      type: String,
      default: () => {
        return '';
      }
    }
  },
  emits: ['getContent'],
  setup(__props, {
    expose: __expose,
    emit: emits
  }) {
    const props = __props;
    const myValue = ref(props.value);
    //监听外部传递进来的的数据变化
    watch(() => props.value, () => {
      myValue.value = props.value;
      emits('getContent', myValue.value);
    });
    function getRender() {
      return md.value.d_render;
      // return md.value.render(myValue.value);
    }

    __expose({
      getRender
    });
    //监听富文本中的数据变化
    watch(() => myValue.value, () => {
      emits('getContent', myValue.value);
    });
    let toolbars = {
      bold: true,
      // 粗体
      italic: true,
      // 斜体
      header: true,
      // 标题
      underline: true,
      // 下划线
      strikethrough: true,
      // 中划线
      mark: true,
      // 标记
      superscript: true,
      // 上角标
      subscript: true,
      // 下角标
      quote: true,
      // 引用
      ol: true,
      // 有序列表
      ul: true,
      // 无序列表
      link: true,
      // 链接
      imagelink: true,
      // 图片链接
      code: true,
      // code
      table: true,
      // 表格
      fullscreen: true,
      // 全屏编辑
      readmodel: true,
      // 沉浸式阅读
      htmlcode: true,
      // 展示html源码
      help: true,
      // 帮助
      /* 1.3.5 */
      undo: true,
      // 上一步
      redo: true,
      // 下一步
      trash: true,
      // 清空
      save: false,
      // 保存（触发events中的save事件）
      /* 1.4.2 */
      navigation: true,
      // 导航目录
      /* 2.1.8 */
      alignleft: true,
      // 左对齐
      aligncenter: true,
      // 居中
      alignright: true,
      // 右对齐
      /* 2.2.1 */
      subfield: true,
      // 单双栏模式
      preview: true // 预览
    };

    const md = ref();
    async function markdownImageAdd(pos, file) {
      console.log(file);
      const formData = new FormData();
      formData.append('img', file);
      try {
        const res = await formDataApi(formData, 'doUploadImgForArticle');
        const url = res; // 假设返回格式为 { url: '图片地址' }
        md.value.$img2Url(pos, url);
      } catch (error) {
        console.log('Image upload failed', error);
      }
    }
    function markdownImageDelete(file) {
      console.log(file[0]); //地址信息
      console.log(file[1]); //miniurl base64  name size 等信息
    }

    return (_ctx, _cache) => {
      const _component_mavon_editor = _resolveComponent("mavon-editor");
      return _openBlock(), _createBlock(_component_mavon_editor, {
        ref_key: "md",
        ref: md,
        modelValue: myValue.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => myValue.value = $event),
        onImgAdd: markdownImageAdd,
        toolbars: _unref(toolbars),
        onImgDel: markdownImageDelete,
        class: "content-show",
        codeStyle: "docco",
        ishljs: true,
        externalLink: _ctx.externalLink
      }, null, 8, ["modelValue", "toolbars", "externalLink"]);
    };
  }
};