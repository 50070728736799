import "core-js/modules/es.array.push.js";
import CodeMirror from "vue-codemirror6";
import { oneDark } from "@codemirror/theme-one-dark";
import { vue } from "@codemirror/lang-vue";
import { javascript } from "@codemirror/lang-javascript";
import { java } from "@codemirror/lang-java";
import { json } from "@codemirror/lang-json";
import { basicSetup, EditorView } from "codemirror";
export default {
  components: {
    CodeMirror
  },
  setup() {
    // 自定义主题
    const myTheme = EditorView.theme({
      "&": {
        color: "black",
        backgroundColor: "#fff"
      }
      // ".cm-content": {
      //   caretColor: "orange",
      // },
      // "&.cm-focused .cm-cursor": {
      //   borderLeftColor: "green",
      // },
      // "&.cm-focused .cm-selectionBackground, ::selection": {
      //   backgroundColor: "#fff",
      // },
      // ".cm-gutters": {
      //   backgroundColor: "red",
      //   color: "blue",
      //   border: "none",
      // },
      // ".cm-line": {
      //   color: "blue",
      //   border: "none",
      // },
    }, {
      dark: false
    });
    // 使用
    // const startState = EditorState.create({
    //   doc: jsonString,
    //   extensions: [java(), myTheme],
    // });
    const extensions = [java(), oneDark];
    return {
      extensions
    };
  },
  created() {
    this.getInit();
  },
  data() {
    return {
      categoryDialogShow: false,
      defaultProps: {
        label: "category_name",
        isLeaf: "leaf",
        id: "id"
      },
      currentCategoryId: "",
      currentTitle: "",
      size: "meduim",
      model_simple_cloud_admin_account: {},
      dataList: [],
      page: {
        pageSize: 20,
        startPage: 1,
        totalResult: 0
      },
      accountDialogShow: false,
      currentRow: {},
      editType: "",
      currentColumns: [],
      queryModel: {},
      categoryList: [],
      totalColumns: [
      // {
      //   name: "category_id",
      //   title: "类别",
      //   isNull: 1,
      //   type: "categoryTree",
      //   radiOptions: [
      //     "后端",
      //     "框架",
      //     "数据库",
      //     "微服务",
      //     "vue",
      //     "html",
      //     "css",
      //     "js",
      //     "项目",
      //     "工具类",
      //     "工具下载",
      //   ],
      // },

      {
        name: "title",
        title: "标题",
        isNull: 1
      }, {
        name: "type",
        title: "类型",
        type: "radio",
        radiOptions: ["文本", "文件"]
      }, {
        name: "content",
        title: "内容",
        type: "textarea"
      }],
      totalInfoColumns: [{
        name: "title",
        title: "标题",
        isNull: 1
      }, {
        name: "type",
        title: "类型",
        type: "radio",
        radiOptions: ["文本", "文件"]
      }, {
        name: "content",
        title: "内容",
        type: "textarea"
      }],
      category_id: "",
      tableMaxHeight: ""
    };
  },
  methods: {
    getInit() {
      //初始化
      this.tableMaxHeight = window.innerHeight - 210;
      this.queryModel = {};
      this.getMyAccount();
      this.getCategory();
    },
    sizeChange(val) {
      this.page.startPage = 1;
      this.page.pageSize = val;
      this.getMyAccount();
    },
    currentChange(val) {
      this.page.startPage = val;
      this.getMyAccount();
    },
    deleteResource(id) {
      this.$confirm("确定删除该资源吗，不可恢复！").then(() => {
        this.doDeleteResource(id);
      }).catch(() => {});
    },
    async doDeleteResource(id) {
      let data = {};
      let params = {};
      params.ephemeralParam = id;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/deleteResource"));
      if (res.code >= 0) {
        this.successMsgBox(res.message);
        this.getInit();
      } else {
        this.errorMsgBox(res.message);
      }
    },
    //removeChild
    removeCategory(data, node) {
      this.$confirm("确定删除该类别吗！").then(() => {
        this.doRemoveCategory(data, node);
      }).catch(() => {});
    },
    doRemoveCategory() {
      node.parent.data.children.forEach((element, index) => {
        if (element.id == data.id) {
          node.parent.data.children.splice(index, 1);
        }
      });
    },
    appendCategory(data) {
      let id = this.generateMixed(5);
      let m = {
        category_name: "",
        id: id,
        children: [],
        isEdit: true
      };
      data.children.push(m);
    },
    //生成n位数字字母混合字符串
    generateMixed(n) {
      var chars = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];
      var res = "";
      for (var i = 0; i < n; i++) {
        var id = Math.floor(Math.random() * 36);
        res += chars[id];
      }
      return res;
    },
    newCategory() {
      let id = this.generateMixed(5);
      this.categoryList.push({
        category_name: "",
        id: id,
        children: [],
        isEdit: true
      });
    },
    //节点选中状态发生变化时的回调，当选中另一个后，当前的的节点状态变化也会触发这个事件
    handleCheckChange(item, checked, self) {
      if (checked) {
        this.category_id = item.id;
        this.$refs.myLinkTree1.setCheckedKeys([item.id]);
        // this.nodeClick(item)
      } else {
        if (this.category_id === item.id) {
          this.$refs.myLinkTree1.setCheckedKeys([item.id]);
        }
      }
      this.currentRow.category_id = this.category_id;
    },
    async addCategory() {
      let data = {};
      let params = {};
      params.ephemeralParamList = this.categoryList;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/addCategory"));
      if (res.code >= 0) {
        this.successMsgBox(res.message);
        this.categoryDialogShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async getCategory() {
      let data = {};
      let params = {};
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/getCategory"));
      if (res.code >= 0) {
        this.categoryList = res.extra;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    openCategory() {
      this.getCategory();
      this.categoryDialogShow = true;
    },
    treeNodeClick(data, node) {
      this.currentCategoryId = data.id;
      this.currentTitle = "";
      this.getMyAccount();
    },
    async getMyAccount() {
      let data = {};
      let params = {};
      params.ephemeralParam = this.currentCategoryId;
      params.ephemeralParam1 = this.currentTitle;
      params.page = this.page;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/getResource"));
      if (res.code >= 0) {
        this.dataList = res.extra;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    handleRemove(f, fileList) {
      fileList.some((element, index) => {
        if (element.name == f.name) {
          fileList.splice(index, 1);
        }
      });
    },
    openEdit(row, editType) {
      this.getCategory();
      this.accountDialogShow = true;
      this.editType = editType;
      if (editType == 1) {
        this.currentRow = row;
        this.currentRow.imgUrlList = [];
        this.currentRow.companyLogoList = [];
        this.currentColumns = this.totalInfoColumns;
      } else if (editType == 2) {
        //新增账户，清空数据
        this.currentRow = {};
        this.currentColumns = this.totalColumns;
      }
    },
    //保存用户信息
    async submit() {
      const formData = new FormData();
      let res;
      if (this.editType == 1) {
        //编辑
        if (this.currentRow.companyLogoList && this.currentRow.companyLogoList.length > 0) {
          formData.append("companyLogof", this.currentRow.companyLogoList[0].raw);
        }
        if (this.currentRow.imgUrlList && this.currentRow.imgUrlList.length > 0) {
          formData.append("imgUrlf", this.currentRow.imgUrlList[0].raw);
        }
        let params = {};
        params.ephemeralParam = this.currentRow;
        formData.append("params", JSON.stringify(params));
        res = await this.withLoading(this.formDataApi(formData, "/addResource"));
      } else if (this.editType == 2) {
        let params = {};
        params.ephemeralParam = this.currentRow;
        formData.append("params", JSON.stringify(params));
        res = await this.withLoading(this.formDataApi(formData, "/addResource"));
      }
      if (res.code >= 0) {
        this.successMsgBox(res.message);
        this.getInit();
        this.accountDialogShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    searchData() {
      alert("这里是搜索！");
    },
    clearSearch() {
      alert("清除所有搜索条件！");
    },
    handleSelectionChange(val) {
      alert("选中数据！");
    }
  }
};