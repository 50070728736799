import {
	createRouter,
	createWebHistory,
	createWebHashHistory,
} from "vue-router";
// import index from "../components/index.vue";
import index from "../components/freeLogin.vue";
import freeLogin from "../components/freeLogin.vue";
import freeManager from "../components/freeManager.vue";
import interviewManager from "../components/interviewManager.vue";
import softManager from "../components/softManager.vue";
import nameManager from "../components/nameManager.vue";
import myWebManager from "../components/myWebManager.vue";
import noteManager from "../components/noteManager.vue";
import courseManager from "../components/courseManager.vue";
import courseWriter from "../components/courseWriter.vue";
import feedbackManager from "../components/feedbackManager.vue";
import htmlWriter from "../components/htmlWriter.vue";
import htmlManager from "../components/htmlManager.vue";
import htmlEditor from "../components/htmlEditor.vue";
import queryManager from "../components/queryManager.vue";

import subjectQueryManager from "../components/subjectQueryManager.vue";
import fileManager from "../components/fileManager.vue";
import wordsManager from "../components/wordsManager.vue";

// import mobileEditor from "../components/mobile/mobileEditor.vue";

const router = createRouter({
	routes: [{
			path: "/",
			component: index
		}, //默认进入login页面

		// { path: "/mobileEditor", component: mobileEditor },

		{
			path: "/admin",
			name: "admin",
			component: () => import("../admin.vue"),
			meta: {
				title: "主页",
				requireAuth: true,
			},
			children: [{
					path: "/index",
					component: index,
					name: "index",
				},
				{
					path: "/freeManager",
					component: freeManager,
					name: "freeManager",
				},
				{
					path: "/interviewManager",
					component: interviewManager
				},
				{
					path: "/softManager",
					component: softManager
				},
				{
					path: "/nameManager",
					component: nameManager,
					name: "nameManager",
				},
				{
					path: "/myWebManager",
					component: myWebManager,
					name: "myWebManager",
				},
				{
					path: "/noteManager",
					component: noteManager,
					name: "noteManager",
				},
				{
					path: "/courseManager",
					component: courseManager,
					name: "courseManager",
				},
				{
					path: "/courseWriter/:id",
					component: courseWriter,
					name: "courseWriter",
				},
				{
					path: "/feedbackManager",
					component: feedbackManager,
					name: "feedbackManager",
				},
				{
					path: "/htmlWriter",
					component: htmlWriter,
					name: "htmlWriter",
				},
				{
					path: "/htmlManager",
					component: htmlManager,
					name: "htmlManager",
				},
				{
					path: "/htmlEditor",
					component: htmlEditor,
					name: "htmlEditor",
				},
				{
					path: "/queryManager",
					component: queryManager,
					name: "queryManager",
				},
				{
					path: "/subjectQueryManager",
					component: subjectQueryManager,
					name: "subjectQueryManager",
				},
				{
					path: "/fileManager",
					component: fileManager,
					name: "fileManager",
				},
				{
					path: "/wordsManager",
					component: wordsManager,
					name: "wordsManager",
				},
			],
		},
		{
			path: "/freeLogin",
			component: freeLogin,
			name: "freeLogin"
		}, //默认进入login页面
	],
	history: createWebHashHistory(),
});
//导入
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
router.beforeEach((to, from, next) => {
	NProgress.start()
	next()
})
router.afterEach(() => {
	NProgress.done()
})
export default router;