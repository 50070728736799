export default {
  created() {
    this.getInit();
  },
  data() {
    return {
      size: "meduim",
      fileList: [],
      searchData: "",
      tableMaxHeight: "",
      dataList: [],
      page: {
        pageSize: 20,
        startPage: 1,
        totalResult: 0
      },
      accountDialogShow: false,
      accountDialogShow1: false,
      starModel: {},
      currentRow: {},
      editType: "",
      fileType: 1,
      subjectLoading: false,
      queryModel: {},
      starColumns: [{
        name: "img",
        title: "图片",
        type: "upload"
      }, {
        name: "name",
        title: "名称"
      }, {
        name: "date",
        title: "月份"
      }, {
        name: "info",
        title: "内容",
        type: "textarea"
      }],
      currentColumns: [{
        name: "category",
        title: "类别",
        isNull: 1,
        type: "radio",
        radiOptions: [{
          name: "汽车",
          value: 0
        }, {
          name: "宠物",
          value: 1
        }, {
          name: "百科",
          value: 2
        }, {
          name: "声音",
          value: 3
        }, {
          name: "图",
          value: 4
        }]
      }, {
        name: "type",
        title: "二级类别",
        type: "radio",
        radiOptions: [{
          name: "猫咪",
          value: 0
        }, {
          name: "狗狗",
          value: 1
        }, {
          name: "鼠鼠",
          value: 2
        }, {
          name: "鸟儿",
          value: 3
        }, {
          name: "鱼",
          value: 4
        }, {
          name: "爬虫",
          value: 5
        }, {
          name: "冷血动物",
          value: 6
        }]
      }, {
        name: "img",
        title: "图片",
        type: "upload"
      }, {
        name: "name",
        title: "名称"
      }, {
        name: "info",
        title: "内容",
        type: "textarea"
      }],
      radiOptions1: [{
        name: "猫咪",
        value: 0
      }, {
        name: "狗狗",
        value: 1
      }, {
        name: "鼠鼠",
        value: 2
      }, {
        name: "鸟儿",
        value: 3
      }, {
        name: "鱼",
        value: 4
      }, {
        name: "爬虫",
        value: 5
      }, {
        name: "冷血动物",
        value: 6
      }],
      radiOptions2: [{
        name: "车标",
        value: 0
      }, {
        name: "路标",
        value: 1
      }, {
        name: "驾考题",
        value: 2
      }],
      radiOptions3: [{
        name: "脑筋急转弯",
        value: 0
      }, {
        name: "冷笑话",
        value: 1
      }, {
        name: "字谜",
        value: 2
      }, {
        name: "成语",
        value: 3
      }, {
        name: "鸡汤",
        value: 4
      }, {
        name: "唐诗宋词",
        value: 5
      }, {
        name: "定理&公式",
        value: 6
      }, {
        name: "世界之最",
        value: 7
      }, {
        name: "斗图",
        value: 8
      }, {
        name: "体育规则",
        value: 9
      }],
      radiOptions4: [{
        name: "自然",
        value: 0
      }, {
        name: "雨水",
        value: 1
      }, {
        name: "深夜",
        value: 2
      }, {
        name: "小情怀",
        value: 3
      }, {
        name: "怀旧铃声",
        value: 4
      }, {
        name: "摇滚",
        value: 5
      }],
      radiOptions5: [{
        name: "卡通",
        value: 0
      }, {
        name: "斗图",
        value: 1
      }, {
        name: "自然",
        value: 2
      }, {
        name: "科技",
        value: 3
      }, {
        name: "职场",
        value: 4
      }, {
        name: "图标",
        value: 5
      }, {
        name: "logo",
        value: 6
      }]
    };
  },
  methods: {
    getInit() {
      this.tableMaxHeight = window.innerHeight - 210;

      //初始化
      this.queryModel = {};
      this.getMyAccount();
    },
    sizeChange(val) {
      this.page.startPage = 1;
      this.page.pageSize = val;
      this.getMyAccount();
    },
    currentChange(val) {
      this.page.startPage = val;
      this.getMyAccount();
    },
    async deleteWeb(id) {
      let data = {};
      let params = {};
      params.ephemeralParam = id;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/deleteWeb"));
      if (res.code >= 0) {
        this.getMyAccount();
      } else {
        this.errorMsgBox(res.message);
      }
    },
    // getCatgeory(category) {},
    // getType(row) {},

    async getMyAccount() {
      let data = {};
      let params = {};
      params.page = this.page;
      params.ephemeralParam1 = this.searchData;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, "/getSubjectForAdmin"));
      if (res.code >= 0) {
        this.dataList = res.extra;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    categoryChange() {
      if (this.currentRow.category == 0) {
        this.currentColumns[1].radiOptions = this.radiOptions2;
      } else if (this.currentRow.category == 1) {
        this.currentColumns[1].radiOptions = this.radiOptions1;
      } else if (this.currentRow.category == 2) {
        this.currentColumns[1].radiOptions = this.radiOptions3;
      } else if (this.currentRow.category == 3) {
        this.currentColumns[1].radiOptions = this.radiOptions4;
      } else if (this.currentRow.category == 4) {
        this.currentColumns[1].radiOptions = this.radiOptions5;
      }
    },
    //保存用户信息
    async submit() {
      const formData = new FormData();
      if (this.fileList && this.fileList.length > 0) {
        formData.append("img", this.fileList[0].raw);
      }
      let params = {};
      params.ephemeralParam = this.currentRow;
      formData.append("params", JSON.stringify(params));
      this.subjectLoading = true;
      const res = await this.withLoading(this.formDataApi(formData, "/savePetOrCar"));
      this.subjectLoading = false;
      if (res.code >= 0) {
        this.fileList = [];
        this.successMsgBox(res.message);
        this.getMyAccount();
        this.accountDialogShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    //保存用户信息
    async submit1() {
      const formData = new FormData();
      if (this.fileList && this.fileList.length > 0) {
        formData.append("img", this.fileList[0].raw);
      }
      let params = {};
      params.ephemeralParam = this.starModel;
      formData.append("params", JSON.stringify(params));
      const res = await this.withLoading(this.formDataApi(formData, "/saveStar"));
      if (res.code >= 0) {
        this.fileList = [];
        this.successMsgBox(res.message);
        this.getMyAccount();
        this.accountDialogShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async deleteInterview(id) {
      //   let data = {};
      //   let params = {};
      //   params.ephemeralParam = id;
      //   data.params = JSON.stringify(params);
      //   const res = await this.withLoading(this.apiAll(data, "/deleteInterview"));
      //   if (res.code >= 0) {
      //     this.successMsgBox(res.message);
      //     this.getInit();
      //   } else {
      //     this.errorMsgBox(res.message);
      //   }
    },
    handleRemove(f, fileList) {
      fileList.some((element, index) => {
        if (element.name == f.name) {
          fileList.splice(index, 1);
        }
      });
    },
    openEdit(row, editType) {
      this.accountDialogShow = true;
    },
    openEdit1(row, editType) {
      this.accountDialogShow1 = true;
    }
  }
};