export default {
  created() {
    this.getInit();
  },
  data() {
    return {
      size: 'meduim',
      searchData: '',
      tableMaxHeight: '',
      model_simple_cloud_admin_account: {},
      dataList: [],
      page: {
        pageSize: 20,
        startPage: 1,
        totalResult: 0
      },
      accountDialogShow: false,
      currentRow: {},
      editType: '',
      queryModel: {},
      currentColumns: [{
        name: 'web_name',
        title: '名称',
        isNull: 1
      }, {
        name: 'type',
        title: '类别',
        type: 'radio',
        radiOptions: ['搜索', '微信', '微博', '邮箱', '社交互动', '接单网', '工具站', 'vueUI']
      }, {
        name: 'web_addr',
        title: '地址'
      }, {
        name: 'web_desc',
        title: '描述'
      }]
    };
  },
  methods: {
    getInit() {
      this.tableMaxHeight = window.innerHeight - 210;

      //初始化
      this.queryModel = {};
      this.getMyAccount();
    },
    gotoWeb(web_addr) {
      window.open(web_addr);
    },
    sizeChange(val) {
      this.page.startPage = 1;
      this.page.pageSize = val;
      this.getMyAccount();
    },
    currentChange(val) {
      this.page.startPage = val;
      this.getMyAccount();
    },
    async deleteWeb(id) {
      let data = {};
      let params = {};
      params.ephemeralParam = id;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, '/deleteWeb'));
      if (res.code >= 0) {
        this.getMyAccount();
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async getMyAccount() {
      let data = {};
      let params = {};
      params.page = this.page;
      params.ephemeralParam1 = this.searchData;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, '/getWeb'));
      if (res.code >= 0) {
        this.dataList = res.extra;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    //保存用户信息
    async submit() {
      //编辑
      let data = {};
      let params = {};
      let list = [];
      params.ephemeralParam = this.currentRow;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll(data, '/saveWeb'));
      if (res.code >= 0) {
        this.getMyAccount();
        this.accountDialogShow = false;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    async deleteInterview(id) {
      //   let data = {};
      //   let params = {};
      //   params.ephemeralParam = id;
      //   data.params = JSON.stringify(params);
      //   const res = await this.withLoading(this.apiAll(data, "/deleteInterview"));
      //   if (res.code >= 0) {
      //     this.successMsgBox(res.message);
      //     this.getInit();
      //   } else {
      //     this.errorMsgBox(res.message);
      //   }
    },
    handleRemove(f, fileList) {
      fileList.some((element, index) => {
        if (element.name == f.name) {
          fileList.splice(index, 1);
        }
      });
    },
    openEdit(row, editType) {
      this.accountDialogShow = true;
    }
  }
};