import TEditor from './TEditor.vue';
import vueMarkdown from './vueMarkdown.vue';
export default {
  components: {
    TEditor
  },
  setup() {},
  data() {
    return {
      fileDialogShow: false,
      fileUrl: [],
      fileList: [],
      fileType: 1,
      currentRow: {},
      isNewSecondName: true,
      secondNameList: [],
      page: {
        pageSize: 100,
        startPage: 1,
        totalResult: 0
      },
      editColumns: [{
        name: 'course_content',
        type: 'TEditor',
        title: '内容'
      }],
      dataList: [],
      currentColumns: [{
        name: 'course_name',
        title: '标题',
        isNull: 1
      }, {
        name: 'second_name',
        title: '二级标题',
        type: 'select-text'
      }, {
        name: 'course_type',
        title: '类别',
        type: 'radio',
        radiOptions: ['java', 'nuxt', 'nodejs', 'mongoose', 'spring', 'mysql', 'springcloud', 'vue', 'js', 'css', 'html', 'react', 'go', 'netty', 'docker', 'es', 'python', 'go', 'uniapp', 'TypeScript']
      }]
    };
  },
  mounted() {
    //传参与接受参数
    console.log(this.$route.params.id);
    if (this.$route.params.id && this.$route.params.id !== '0' && this.$route.params.id !== 0) {
      //获取单个course信息
      this.currentRow._id = this.$route.params.id;
      this.getCourseByIdForAdmin();
    }
  },
  methods: {
    async getCourseByIdForAdmin() {
      const res = await this.withLoading(this.apiAll2({
        _id: this.currentRow._id
      }, 'getCourseByIdForAdmin'));
      if (res) {
        this.currentRow = res;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    newCourse() {
      this.confirm('确认新增教程吗？', () => {
        this.currentRow = {};
        this.dataList = [];
      });
    },
    copyThirdRegistUrl(content) {
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(content).then(() => {
          this.successMsgBox('复制成功');
        });
      } else {
        copyToClip(content);
      }
    },
    copyToClip(content) {
      var aux = document.createElement('input');
      aux.setAttribute('value', content);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand('copy');
      document.body.removeChild(aux);
      if (message == null) {
        this.successMsgBox('复制成功');
      } else {
        this.successMsgBox(message);
      }
    },
    handleRemove(f, fileList) {
      this.fileList.some((element, index) => {
        if (element.name == f.name) {
          this.fileList.splice(index, 1);
        }
      });
    },
    async uploadImg() {
      if (!this.fileList || this.fileList.length <= 0) {
        return false;
      }
      const formData = new FormData();
      if (this.fileList && this.fileList.length > 0) {
        this.fileList.forEach(element => {
          formData.append('imgs', element.raw);
        });
      }
      const res = await this.withLoading(this.formDataApi2(formData, 'uploadImg'));
      this.fileUrl = res;
      this.successMsgBox(res.message);
      // this.fileDialogShow = false;
    },

    //保存用户信息
    async submit() {
      if (!this.currentRow.course_name) {
        this.errorMsgBox('标题不能为空！');
        return false;
      }
      if (!this.currentRow.course_content) {
        this.errorMsgBox('内容不能为空！');
        return false;
      }
      //编辑
      let data = {};
      let params = {};
      let list = [];
      params.ephemeralParam = this.currentRow;
      data.params = JSON.stringify(params);
      const res = await this.withLoading(this.apiAll2(this.currentRow, 'saveCourse'));
      if (res) {
        this.successMsgBox('新建成功！');
        this.currentRow._id = res._id;
      } else {
        this.errorMsgBox(res.message);
      }
    },
    getContent(content) {
      this.currentRow.course_content = content;
    },
    async getCourseSecondName() {
      const res = await this.withLoading(this.apiAll2({
        course_type: this.currentRow.course_type
      }, 'getCourseSecondName'));
      this.secondNameList = res;
    },
    async getCourseBySecondNameForAdmin(val) {
      const res = await this.withLoading(this.apiAll2({
        page: this.page,
        name: val
      }, 'getCourseBySecondNameForAdmin'));
      if (res) {
        this.dataList = res.items;
        this.page = res.page;
      } else {
        this.errorMsgBox(res.message);
      }
    }
  }
};