import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_button = _resolveComponent("el-button");
  const _component_el_col = _resolveComponent("el-col");
  const _component_CodeMirror = _resolveComponent("CodeMirror");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock("div", null, [_createVNode(_component_el_row, {
    gutter: 10
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      style: {
        "padding": "0 0 0 10px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_el_button, {
        icon: "CircleCheck",
        size: _ctx.size,
        type: "primary",
        onClick: $options.submit
      }, {
        default: _withCtx(() => [_createTextVNode("提交")]),
        _: 1
      }, 8, ["size", "onClick"]), _createVNode(_component_el_button, {
        icon: "CircleCheck",
        size: _ctx.size,
        type: "primary",
        onClick: $options.open
      }, {
        default: _withCtx(() => [_createTextVNode("打开")]),
        _: 1
      }, 8, ["size", "onClick"])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 24,
      style: {
        "padding": "10px"
      }
    }, {
      default: _withCtx(() => [_createVNode(_component_CodeMirror, {
        style: _normalizeStyle({
          width: '100%',
          maxHeight: `${$data.mainHeight}px`,
          overflow: 'auto',
          minHeight: `${$data.mainHeight}px`,
          fontWeight: 'bold',
          fontSize: `20px`
        }),
        modelValue: $data.course_content,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.course_content = $event),
        extensions: $setup.extensions1
      }, null, 8, ["style", "modelValue", "extensions"])]),
      _: 1
    })]),
    _: 1
  })]);
}